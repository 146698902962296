const measurementLayerOptions = [
  {
    label: 'Chlorophyll-a (μg/l)',
    value: 'TERRASCOPE_S2_CHL_V1',
    translationKey: 'chl-a',
    tooltip: {
      text: (
        'The Chlorophyll-a concentration (Chl-a) is an indicator of phytoplankton abundance '
        + 'and biomass in coastal and estuarine waters. It can be used to classify the trophic '
        + 'condition of a waterbody. Although algae are a natural part of freshwater ecosystems, '
        + 'too much algae can cause aesthetic problems such as green scums and bad odours, and '
        + 'can result in decreased levels of dissolved oxygen. Some algae also produce toxins '
        + 'that can be of public health concern when they are found in high concentrations. '
        + 'The Chl-a is calculated using Mixture Density Network by '
        + '[Pahlevan et al. (2020)](https://doi.org/10.1016/j.rse.2019.111604).'
      ),
      img: '/img/tooltips/chl-a.png',
    },
  },
  {
    label: 'Total suspended matter (mg/l)',
    value: 'TERRASCOPE_S2_SPM_V1',
    translationKey: 'tsm',
    tooltip: {
      text: (
        'The Total Suspended Matter (TSM) refers to small solid particles which remain in suspension '
        + 'in water as a colloid or due to the motion of the water. Suspended matter can be removed by '
        + 'sedimentation because of their comparatively large size. TSM and Turbidity are derived using '
        + 'the semi-empirical relation of Nechad et al. ([2009](http://dx.doi.org/10.1117/12.830700), '
        + '[2010](https://doi.org/10.1016/j.rse.2009.11.022)), in combination with a band-switching '
        + 'approach based on the work of [Dogliotti et al. (2015)](https://doi.org/10.1016/j.rse.2014.09.020) '
        + 'to tackle both low TSM and high TSM values.'
      ),
    },
  },
  {
    label: 'Turbidity (FNU)',
    value: 'TERRASCOPE_S2_TUR_V1',
    translationKey: 'turbidity',
    tooltip: {
      text: (
        'The Turbidity is a measure of relative clarity of a liquid. It is an optical characteristic of '
        + 'water and is a measure of the amount of light that is scattered by material in the water when a '
        + 'light is shined through the water sample. The higher the intensity of the scattered light, the '
        + 'higher the turbidity. TSM and Turbidity are derived using the semi-empirical relation of Nechad et al. '
        + '([2009](http://dx.doi.org/10.1117/12.830700), [2010](https://doi.org/10.1016/j.rse.2009.11.022)), '
        + 'in combination with a band-switching approach based on the work of '
        + '[Dogliotti et al. (2015)](https://doi.org/10.1016/j.rse.2014.09.020) to tackle both low TSM '
        + 'and high TSM values.'
      ),
    },
  },
  {
    label: 'none',
    value: 'none',
  },
];

const additionalLayerOptions = [
  { 
    label: 'True colour image (Level-2A)',
    value: 'TERRASCOPE_S2_RHOW_V1',
    tooltip: {
      text: (
        'Water-leaving reflectance (ρw): The ratio of water leaving radiance over downwelling irradiance just '
        + 'above water surface. The product is a geometrically and atmospherically corrected ([iCor: De Keukelaere '
        + 'et al., 2018](https://doi.org/10.1080/22797254.2018.1457937)) version of the ESA Sentinel-2 L1C data with '
        + 'additional information on pixel classification ([Wevers et al., 2021](https://doi.org/10.5281/zenodo.5788067)).'
      ),
    },
  },
  {
    label: 'Tile footprints',
    value: 'TILE_FOOTPRINTS',
    translationKey: 'tile_footprints',
    tooltip: {
      text: 'This option draws the footprints of the Sentinel-2 tiles on the map.',
    },
  },
  {
    label: 'Cloud mask',
    value: 'CLOUD_MASK',
    translationKey: 'cloud_mask',
    tooltip: {
      text: (
        'This cloud mask is date specific. It is derived from the [pixel classification layer]'
        + '(https://docs.terrascope.be/DataProducts/Sentinel-2/references/VITO_S2_ATBD_S2_WATER_QUALITY_V120.pdf#page=15) '
        + 'that accompanies each scene. The classes used for this mask are the following: 2 (cloud), 3 '
        + '(cloud ambiguous), 4 (cloud sure), 5 (cloud buffer), 6 (cloud shadow), 12 (cirrus sure) and 13 (cirrus ambiguous).'
      ),
    },
  },
  {
    label: 'Water mask',
    value: 'WATER_MASK',
    translationKey: 'water_mask',
    tooltip: {
      text: (
        'This water mask is date specific. It is derived from the [pixel classification layer]'
        + '(https://docs.terrascope.be/DataProducts/Sentinel-2/references/VITO_S2_ATBD_S2_WATER_QUALITY_V120.pdf#page=15) '
        + 'that accompanies each scene. The class used for this mask is 15 (clear water).'
      ),
    },
  },
]

export { measurementLayerOptions, additionalLayerOptions };