import Sidepanel from '@App/components/Sidepanel/Sidepanel';
import DownloadForm from './DownloadForm/DownloadForm';
import DownloadMap from './DownloadMap/DownloadMap';

import './download.styl';

function Download() {
  return (
    <div className="download">
      <Sidepanel 
        className="download__sidepanel"
        title="Download"
        icon="img/pages/download-icon.svg"
        intro="Choose your product. You can download full tiles or smaller areas."
      >
        <DownloadForm />
      </Sidepanel>
      <DownloadMap />
    </div>
  )
}

export default Download;