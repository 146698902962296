/* eslint-disable max-lines */
import {
  useRef, useState, useEffect, useCallback, 
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'leaflet';

import tilesJson from '@App/data/15tiles-tanganyika-kivu-with-tileid.json';
import { getBounds } from '@services/utils.service';

import { setSelectedTiles, focusMapOnFeature } from '../download.slice';

import { mapFactory, addLayersFromConfigToMap } from './download-map.factory';
import { style, styleActive } from './download-map.constants';

import Controls from './Controls/Controls';
import SelectTilesWithinCustomArea from './SelectTilesWithinCustomArea/SelectTilesWithinCustomArea';

import 'leaflet/dist/leaflet.css';
import './download-map.styl';

function DownloadMap() {
  const dispatch = useDispatch();
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);

  const selectionType = useSelector(state => state.download.selectionType);
  const selectedTiles = useSelector(state => state.download.selectedTiles);
  const focusFeature = useSelector(state => state.download.focusFeature);

  const availableTilesLayer = useRef(L.geoJSON(tilesJson, style));
  const activeTilesLayer = useRef(L.geoJSON([], styleActive));

  const setNewTiles = useCallback(async (activeTiles, availableTiles) => {
    activeTilesLayer.current.clearLayers();
    activeTilesLayer.current.addData(activeTiles);
    availableTilesLayer.current.clearLayers();
    availableTilesLayer.current.addData(availableTiles);
    dispatch(setSelectedTiles(activeTiles));
  }, [dispatch]);

  const addTiles = useCallback(async (tiles) => {
    const activeTiles = [...activeTilesLayer.current.toGeoJSON().features, ...tiles];
    const availableTiles = availableTilesLayer.current.toGeoJSON().features.filter((f) => {
      return !tiles.find(tile => f.properties.tileid === tile.properties.tileid);
    });

    setNewTiles(activeTiles, availableTiles);
  }, [setNewTiles]);

  const removeTiles = useCallback((tiles) => {
    const activeTiles = activeTilesLayer.current.toGeoJSON().features.filter((f) => {
      return !tiles.find(tile => f.properties.tileid === tile.properties.tileid);
    });
    const availableTiles = [...availableTilesLayer.current.toGeoJSON().features, ...tiles];

    setNewTiles(activeTiles, availableTiles);
  }, [setNewTiles]);

  // INIT MAP
  useEffect(() => {
    const mapInstance = mapFactory(mapRef.current);
    setMap(mapInstance);
    return () => mapInstance.remove();
  }, []);

  // ON MAP LOAD
  useEffect(() => {
    if (!map) return;
    addLayersFromConfigToMap(map);
    availableTilesLayer.current.on('click', ({ propagatedFrom: { feature: tile } }) => addTiles([tile]));
    activeTilesLayer.current.on('click', ({ propagatedFrom: { feature: tile } }) => removeTiles([tile]));
  }, [map, addTiles, removeTiles]);

  // KEEP TILE SELECTION IN SYNC WITH REDUX STATE
  useEffect(() => {
    if (!activeTilesLayer.current || !selectedTiles['click-selection'] 
      || selectionType !== 'click-selection') return;
    if (selectedTiles['click-selection'].length 
      && !activeTilesLayer.current.toGeoJSON().features.length) {
      addTiles(selectedTiles['click-selection']);
    }
  }, [addTiles, selectedTiles, selectionType]);

  // SHOW/HIDE TILE FEATURES
  useEffect(() => {
    if (!map) return;
    if (selectionType === 'click-selection') {
      availableTilesLayer.current.addTo(map);
      activeTilesLayer.current.addTo(map);
      activeTilesLayer.current.bringToFront();
    } else {
      map.removeLayer(availableTilesLayer.current);
      map.removeLayer(activeTilesLayer.current);
    }
  }, [map, selectionType]);

  // TILES GOT REMOVED THOUGH REDUX
  useEffect(() => {
    if (selectedTiles['click-selection'].length 
      < activeTilesLayer.current.toGeoJSON().features.length) {
      const tilesToRemove = activeTilesLayer.current.toGeoJSON().features.filter((tile) => {
        return !selectedTiles['click-selection'].find(t => t.properties.tileid === tile.properties.tileid);
      })
      removeTiles(tilesToRemove);
    }
  }, [selectedTiles, removeTiles]);

  useEffect(() => {
    if (!map || !focusFeature) return;
    map.fitBounds(getBounds(focusFeature));
    dispatch(focusMapOnFeature(null));
  }, [map, dispatch, focusFeature]);

  return (
    <div ref={mapRef} className="leaflet-map download-map">
      <Controls map={map} />
      <SelectTilesWithinCustomArea map={map} />
    </div>
  );
}

export default DownloadMap;