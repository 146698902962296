/* eslint-disable max-lines */
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Icon, Button } from '@nazka/nazka.mapframe.components';
import { faInfoCircle, faPlus } from '@fortawesome/pro-regular-svg-icons';

import Tooltip from '@App/components/Tooltip/Tooltip';
import ImageTooltip from '@App/components/Tooltip/ImageTooltip/ImageTooltip';
import CloudCoverageSlider from '@App/components/CloudCoverageSlider/CloudCoverageSlider';
import DatePicker from '@App/components/DatePicker/DatePicker';
import Radiogroup from '@App/components/Radiogroup/Radiogroup';
import ExtraLayers from './ExtraLayers/ExtraLayers';

import { measurementLayerOptions } from '../explore.constants';

import { 
  setCloudCoverage, setDate, setMeasurementType, setCompare, setCompareDate, 
  setCompareMeasurementType, setShowAdditionalLayer, setAdditionalLayer,
} from '../explore.slice';
import { useExploreDates } from '../explore.hooks';

import './explore-form.styl';

function ExploreForm() {
  const dispatch = useDispatch();

  const cloudCoverage = useSelector(state => state.explore.cloudCoverage);
  const date = useSelector(state => state.explore.date);
  const measurementType = useSelector(state => state.explore.measurementType);
  const compare = useSelector(state => state.explore.compare);
  const compareDate = useSelector(state => state.explore.compareDate);
  const compareMeasurementType = useSelector(state => state.explore.compareMeasurementType);
  const showAdditionalLayer = useSelector(state => state.explore.showAdditionalLayer);
  const additionalLayer = useSelector(state => state.explore.additionalLayer);
  const mapBounds = useSelector(state => state.explore.mapBounds);

  const {
    highlightedDays,
    cloudyDays,
    allDays,
  } = useExploreDates(mapBounds, cloudCoverage, '2022-11-01', '2023-12-31');

  useEffect(() => {
    if (!highlightedDays.length) return;
    const firstDay = highlightedDays[highlightedDays.length - 1];
    dispatch(setDate(new Date(`${firstDay} UTC`).toISOString()));
    dispatch(setCompareDate(new Date(`${firstDay} UTC`).toISOString()));
  }, [highlightedDays, dispatch])

  return (
    <div className="explore-form">
      <div className="explore-form__sensor">
        <p>Sensor: Sentinel-2</p>
        <Tooltip
          place="right"
          id="download_sensor_tooltip"
          style={{ opacity: 1 }}
          tooltip={(
            <ImageTooltip 
              text={(
                'The Copernicus Sentinel-2 mission comprises a constellation of two '
                + 'polar-orbiting satellites placed in the same sun-synchronous orbit, phased '
                + 'at 180° to each other. Its wide swath width (290 km) and high revisit time '
                + '(5 days at the equator with two 2 satellites under cloud-free conditions, '
                + "2-3 days at mid-latitudes) will support monitoring of Earth's surface changes."
              )}
              img="/img/tooltips/Sentinel-2.jpg"
            />
        )}
        >
          <Icon 
            className="explore-form__sensor__icon" 
            icon={faInfoCircle}
            rootElProps={{ 
              'data-tooltip-id': 'download_sensor_tooltip',
            }}
          />
        </Tooltip>
      </div>
      <CloudCoverageSlider
        min={0}
        max={100}
        value={cloudCoverage}
        setValue={v => dispatch(setCloudCoverage(v))}
      />
      <DatePicker
        value={date}
        onChange={(newValue) => {
          dispatch(setDate(newValue));
          if (!compare) dispatch(setCompareDate(newValue)); 
        }}
        shouldDisableDate={d => !allDays.includes(new Date(d).toDateString())}
        highlightedDays={highlightedDays}
        greyedOutDays={cloudyDays}
        allDays={allDays}
        placeholder="No date selected"
        minDate={new Date('2022-11-01')}
        maxDate={new Date('2023-12-31')}
      />
      <label id="explore-form-water-quality">
        <span className="explore-form__label">
          Water quality indicator
        </span>
        <Radiogroup
          name="measurement-type"
          className="explore-form__label__radio-group"
          radios={measurementLayerOptions}
          selectedValue={measurementType}
          onChange={o => dispatch(setMeasurementType(o))}
          showTooltips
        />
      </label>
      <div className={`explore-form__compare${compare ? ' explore-form__compare--active' : ''}`}>
        <Button 
          onClick={() => dispatch(setCompare(!compare))}
          className="explore-form__compare__toggle"
          icon={faPlus}
          hover={false}
        >
          Compare
        </Button>
        <DatePicker
          value={compareDate}
          onChange={newValue => dispatch(setCompareDate(newValue))}
          highlightedDays={highlightedDays}
          shouldDisableDate={d => !allDays.includes(new Date(d).toDateString())}
          greyedOutDays={cloudyDays}
          allDays={allDays}
          placeholder="No date selected"
        />
        <label>
          <span className="explore-form__label">
            Water quality indicator
          </span>
          <Radiogroup
            name="compare-measurement-type"
            className="compare-measurement-type__radio-group"
            radios={measurementLayerOptions}
            selectedValue={compareMeasurementType}
            onChange={o => dispatch(setCompareMeasurementType(o))}
          />
        </label>
      </div>
      <ExtraLayers 
        showAdditionalLayer={showAdditionalLayer}
        setShowAdditionalLayer={v => dispatch(setShowAdditionalLayer(v))}
        additionalLayer={additionalLayer}
        setAdditionalLayer={v => dispatch(setAdditionalLayer(v))}
      />
    </div>
  )
}

export default ExploreForm;