import { useState, useEffect } from 'react';
import { Icon } from '@nazka/nazka.mapframe.components';

import './sidepanel.styl';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons'; 

function Sidepanel(props) {
  const {
    title, icon, intro, children, className, 
  } = props;

  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    setTimeout(() => window.dispatchEvent(new Event('resize')), 300);
  }, [isOpen]);

  return (
    <div className={`sidepanel${isOpen ? ' is-open' : ''}${className ? ` ${className}` : ''}`}>
      <div className="sidepanel__toggle" onClick={() => setIsOpen(!isOpen)}>
        <Icon icon={faChevronLeft} className="sidepanel__toggle__icon" />
      </div>
      <div className="sidepanel__overflow-wrapper">
        <div className="sidepanel__content">
          { title && (
            <div className="sidepanel__content__header">
              {icon && (<Icon icon={icon} className="sidepanel__content__header__icon" />)}
              <h2 className="sidepanel__content__header__title">{title}</h2>
            </div>
          )}
          {intro && (<div className="sidepanel__content__intro">{intro}</div>)}
          <div className="sidepanel__content__children">
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sidepanel;