import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Explore from '@App/pages/Explore/Explore';
import Download from '@App/pages/Download/Download';

function Redirect({ to }) {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(to);
  }, [navigate, to]);

  return null;
}

const routes = [
  {
    name: 'Explore', 
    path: '/', 
    element: <Redirect to="/explore" />,
  },
  {
    name: 'Explore', 
    path: '/explore', 
    element: <Explore />,
    navPosition: 'top',
    icon: 'img/nav/explore-icon.svg',
  },
  {
    name: 'Download', 
    path: '/download', 
    element: <Download />,
    navPosition: 'top',
    icon: 'img/nav/download-icon.svg',
  },
];

export default routes;
