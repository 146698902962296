function createWaterLayers(products) {
  if (!products) return [];
  const titilerUrl = '?';

  return products.map((p) => {
    const { bbox, properties } = p;
    const { href } = properties.links.related[0];

    const colormap = encodeURIComponent(JSON.stringify({
      1: '#2668B0',
    }));

    const bounds = [[bbox[1], bbox[0]], [bbox[3], bbox[2]]];
    return L.tileLayer.canvas(`${titilerUrl}/cog/tiles/{z}/{x}/{y}@2x.png?url=${encodeURIComponent(href)}&bidx=15&colormap=${colormap}&nodata=0`, {
      bounds,
      opacity: 1,
    }).setZIndex(4);
  })
}

export { createWaterLayers };