import { formatDateToYYYYMMDD } from '@services/utils.service';

function shouldClip(clip, tilesWithDates) {
  return clip && tilesWithDates.length > 0;
}

function shouldMerge(mergeAndClip, tilesWithDates) {
  return mergeAndClip && tilesWithDates.length > 1;
}

function createValidationPayload(measurements, tilesWithDates, clip, mergeAndClip, polygon) {
  const validationPayload = {
    collectionIds: measurements.map(type => type.value),
    tiles: tilesWithDates
      .filter(({ dates }) => dates.length > 0)
      .map(({ tileid, dates }) => ({
        tileId: tileid,
        dates: dates.map(date => formatDateToYYYYMMDD(date)),
      })),
  };

  if (shouldClip(clip, tilesWithDates) || shouldMerge(mergeAndClip, tilesWithDates)) {
    validationPayload.polygon = polygon.toGeoJSON();

    if (shouldMerge(mergeAndClip, tilesWithDates)) {
      validationPayload.merge = true;
    }
  }

  return validationPayload;
}

function getOrderType(clip, mergeAndClip, selectedTilesWithDates) {
  if (shouldClip(clip, selectedTilesWithDates) 
    || shouldMerge(mergeAndClip, selectedTilesWithDates)) {
    return 'DOWNLOAD CLIP';
  }

  return 'DOWNLOAD TILE';
}

export {
  createValidationPayload,
  getOrderType,
};