import { intersect, featureCollection } from '@turf/turf';
import tilesJson from '@App/data/15tiles-tanganyika-kivu-with-tileid.json';

function getTilesInDrawnRectangle(feature) {
  return tilesJson.features.reduce((acc, curr) => {
    const intersects = intersect(featureCollection([curr, feature]));

    if (intersects) {
      acc.overlappingTiles.push(curr);
    } else {
      acc.availableTiles.push(curr);
    }

    return acc;
  }, { overlappingTiles: [], availableTiles: [] });
}

export {
  getTilesInDrawnRectangle,
};