import ProductWMTSLayer from './ProductWMTSLayer/ProductWMTSLayer';
import TrueColorImageLayer from './TrueColorImageLayer/TrueColorImageLayer';
import TileFootprintsLayer from './TileFootprintsLayer/TileFootprintsLayer';
import CloudMaskLayer from './CloudMaskLayer/CloudMaskLayer';
import WaterMaskLayer from './WaterMaskLayer/WaterMaskLayer';

function DataLayers(props) {
  return (
    <>
      <ProductWMTSLayer {...props} />
      <TrueColorImageLayer {...props} />
      <TileFootprintsLayer {...props} />
      <CloudMaskLayer {...props} />
      <WaterMaskLayer {...props} />
    </>
  )
}

export default DataLayers;