import { useRef } from 'react';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { Icon } from '@nazka/nazka.mapframe.components';
import Tooltip from '@App/components/Tooltip/Tooltip';
import ImageTooltip from '@App/components/Tooltip/ImageTooltip/ImageTooltip';

import { generateUniqueId } from '@services/utils.service'

import './radio.styl';

function Radio(props) {
  const {
    label, name, value, checked, onChange, showTooltips, tooltip, disabled,
  } = props;
  const idRef = useRef(generateUniqueId());

  return (
    <div className="radio">
      <input
        id={idRef.current}
        className="radio__input"
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <label htmlFor={idRef.current} className="radio__label">
        { label }
      </label>
      {showTooltips && tooltip && (
        <Tooltip
          place="right"
          id={value}
          tooltip={(<ImageTooltip text={tooltip.text} img={tooltip.img} url={tooltip.url} />)}
        >
          <Icon 
            icon={faInfoCircle} 
            className="radio__info" 
            rootElProps={{ 
              'data-tooltip-id': value,
            }}
          />
        </Tooltip>
      )}
    </div>
  );
}

export default Radio;