/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Tooltip } from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';

import Portal from '@utils/Portal/Portal';

import { tooltipProps } from './tooltip.constants';

import 'react-tooltip/dist/react-tooltip.css'
import './tooltip.styl';

function CustomTooltip(props) {
  const {
    id,
    tooltip,
    className,
    children,
    ...specificTooltipProps
  } = props;
  
  if (!tooltip || !id || (React.Children.count(children) !== 1)) {
    return children;
  }

  const uniqueId = uuidv4();

  return (
    <>
      {React.cloneElement(children, { 
        id: uniqueId,
      })}
      <Portal>
        <Tooltip 
          {...tooltipProps}
          {...specificTooltipProps}
          id={id}
          className={`tooltip ${className || ''}`}
          textColor="#333"
          arrowColor="#fff"
          delayHide={100}
          delayShow={500}
          clickable
          borderColor="#d4d4d4"
        >
          {tooltip}
        </Tooltip>
      </Portal>
    </>
  );
}

export default CustomTooltip;