import { useEffect, useRef, useMemo } from 'react';
import { getDateGroups } from './utils.service';

function usePrev(value) {
  const ref = useRef();
  
  useEffect(() => {
    ref.current = value;
  }, [value]);
  
  return ref.current;
}

function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    [ref, handler],
  );
}

function useDateGroups(dates, cloudCover) {
  return useMemo(
    () => getDateGroups(dates, cloudCover),
    [dates, cloudCover],
  );
}

export {
  usePrev,
  useOnClickOutside,
  useDateGroups,
}