import { useEffect, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';

import { measurementLayerOptions } from '@App/pages/Explore/explore.constants';

function ProductWMTSLayer({ map }) {
  const measurementType = useSelector(state => state.explore.measurementType);
  const date = useSelector(state => state.explore.date);
  const compare = useSelector(state => state.explore.compare);
  const compareMeasurementType = useSelector(state => state.explore.compareMeasurementType);
  const compareDate = useSelector(state => state.explore.compareDate);
  
  const compareControl = useMemo(() => L.control.compare(), []);
  const compareLayer = useRef();
  const layer = useRef();

  useEffect(() => {
    if (!map || !compareControl) return;
    if (compare) compareControl.addTo(map);
    else map.removeControl(compareControl);
  }, [map, compare, compareControl]);

  useEffect(() => {
    if (map && date && measurementType !== 'none') {
      const wmtsUrl = (
        'https://services.terrascope.be/wmts/v2?Service=WMTS&Request=GetTile&Format=image/png'
        + '&Version=1.0.0&tilematrixset=EPSG:3857&TileMatrix=EPSG:3857:{z}&TileCol={x}&TileRow={y}'
        + `&layer=${measurementType}&TIME=${date.split('T')[0]}&style=`
      );

      layer.current = L.tileLayer(wmtsUrl, { pane: 'product-layers' });
      layer.current.addTo(map);
      if (compare) compareControl.setLeftLayers([layer.current]);
    }

    if (map && compare && compareDate && compareMeasurementType !== 'none') {
      const wmtsUrl = (
        'https://services.terrascope.be/wmts/v2?Service=WMTS&Request=GetTile&Format=image/png'
        + '&Version=1.0.0&tilematrixset=EPSG:3857&TileMatrix=EPSG:3857:{z}&TileCol={x}&TileRow={y}'
        + `&layer=${compareMeasurementType}&TIME=${compareDate.split('T')[0]}&style=`
      );

      compareLayer.current = L.tileLayer(wmtsUrl, { pane: 'product-layers' }).addTo(map);
      compareControl.setRightLayers([compareLayer.current]);
    }

    return () => {
      layer?.current?.remove();
      compareLayer?.current?.remove();
    };
  }, [
    map, measurementType, date, compare, compareControl, compareDate, 
    compareMeasurementType,
  ]);

  useEffect(() => {
    if (!map || !compare || !compareControl) return;
    compareControl.setLeftLayersNameAndDate(
      measurementLayerOptions.find(o => o.value === measurementType)?.label,
      date ? format(new Date(date), 'd MMMM yyyy') : 'No date selected',
    );
    compareControl.setRightLayersNameAndDate(
      measurementLayerOptions.find(o => o.value === compareMeasurementType)?.label,
      compareDate ? format(new Date(compareDate), 'd MMMM yyyy') : 'No date selected',
    );
  }, [
    measurementType, compareMeasurementType, date, compareDate, map, compare, compareControl,
  ]);

  return null;
}

export default ProductWMTSLayer;