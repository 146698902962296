import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cookieValue: undefined,
}

const cookies = createSlice({
  name: 'cookies',
  initialState,
  reducers: {
    setCookieValue: (state, action) => {
      state.cookieValue = action.payload;
    },
  },
})

export const { setCookieValue } = cookies.actions;

export default cookies.reducer;