import { useState, useEffect } from 'react';
import { fetchControls } from './controls.utils';

const Controls = ({ map }) => {
  const [controls, setControls] = useState(null);

  useEffect(() => {
    let isMounted = true;

    if (map) {
      fetchControls(map).then((fetchedControls) => {
        if (isMounted) {
          setControls(fetchedControls);
        }
      });
    }

    return () => {
      isMounted = false;
    };
  }, [map]);

  return controls;
};

export default Controls;