import { createRoot } from 'react-dom/client';

L.Control.Search = L.Control.extend({
  options: {
    position: 'topright',
  },

  initialize(element, options) {
    L.Util.setOptions(this, options);
    this.element = element;
  },

  onAdd() {
    const container = L.DomUtil.create('div', 'leaflet-control-search');
    const root = createRoot(container);

    root.render(this.element);

    return container;
  },
})

L.search = (element, options) => {
  return new L.Control.Search(element, options);  
}