const tooltipProps = {
  borderColor: '#333',
  delayHide: 500,
  delayShow: 500,
  delayUpdate: 500,
  effect: 'solid',
  textColor: '#333',
  type: 'light',
};

export {
  tooltipProps,
};