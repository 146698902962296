import { get, set, del } from 'idb-keyval';
import { QueryClient } from '@tanstack/react-query';
import { persistQueryClient } from '@tanstack/react-query-persist-client';

import { MAX_CACHE_AGE, IDB_VALID_KEY } from './query-client.constants';

function createQueryClient() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: MAX_CACHE_AGE,
        refetchOnWindowFocus: false,
        retry: 0,
      },
    },
  });
  
  const IDBPersister = {
    persistClient: async (client) => {
      set(IDB_VALID_KEY, client);
    },
    restoreClient: async () => {
      return get(IDB_VALID_KEY);
    },
    removeClient: async () => {
      await del(IDB_VALID_KEY);
    },
  };
  
  persistQueryClient({
    queryClient,
    persister: IDBPersister,
    maxAge: MAX_CACHE_AGE,
    dehydrateOptions: {
      shouldDehydrateQuery: (query) => {
        // do not store unsuccesful queries and queries related to user
        if (query.state.status !== 'success' || query.queryKey[0] === 'user') return false;

        return true;
      },
    },
  });

  return queryClient;
}

const queryClient = createQueryClient();

export default queryClient;