const style = {
  color: '#333',
  weight: 1,
  opacity: 0.2,
  fillColor: null,
  fillOpacity: 0.1,
}

const styleActive = {
  color: '#4191E2',
  weight: 2,
  opacity: 1,
  fillColor: null,
  fillOpacity: 0.4,
}

const stylePartOfRectangle = {
  color: '#333',
  weight: 1,
  opacity: 0.2,
  fillColor: null,
  fillOpacity: 0.2,
}

const styleDrawRectangle = {
  color: '#333',
  weight: 4,
  opacity: 1,
  fillColor: null,
  fillOpacity: 0,
}

const styleActiveDrawRectangle = {
  color: '#4191E2',
  weight: 4,
  opacity: 1,
  fillColor: null,
  fillOpacity: 0.4,
}

export {
  style, styleActive, stylePartOfRectangle, styleDrawRectangle, styleActiveDrawRectangle, 
};