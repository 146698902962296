import { generateUniqueId } from '@services/utils.service';

import './image-tooltip.styl';

function stringWithMarkdownLinksToElement(string) {
  const regex = /\[([^[\]]*)\]\((.*?)\)/gm;
  const stringArray = String(string).split(regex);
  const elementArray = [];

  for (let i = 0; i < stringArray.length;) {
    if (i % 3 === 0) {
      elementArray.push(<span key={generateUniqueId()}>{stringArray[i]}</span>);
      i += 1;
    } else {
      const text = stringArray[i];
      const url = stringArray[i + 1];
      elementArray.push(<a href={url} target="_blank" rel="noreferrer" key={generateUniqueId()}>{text}</a>);
      i += 2;
    }
  }
  
  return (
    [...elementArray]
  );
}

function ImageTooltip(props) {
  const { text, img } = props;
  const textElement = typeof text !== 'object' ? stringWithMarkdownLinksToElement(text) : text;

  return (
    <div className="image-tooltip">
      {img && <div className="image-tooltip__img" style={{ backgroundImage: `url(${img})` }} /> }
      <div className="image-tooltip__text">
        {textElement}
      </div>
    </div>
  )
}

export default ImageTooltip;