import Sidepanel from '@App/components/Sidepanel/Sidepanel';
import ExploreForm from './ExploreForm/ExploreForm';
import ExploreMap from './ExploreMap/ExploreMap';

import './explore.styl';

function Explore() {
  return (
    <div className="explore">
      <Sidepanel 
        className="explore__sidepanel"
        title="Explore"
        icon="img/pages/explore-icon.svg"
        intro="Monitor the quality of water bodies in near-real time. Water quality indicators are derived from Copernicus Sentinel-2 images."
      >
        <ExploreForm />
      </Sidepanel>
      <ExploreMap />
    </div>
  );
}

export default Explore;