function createCloudLayers(products) {
  if (!products) return [];
  const titilerUrl = '?';

  return products.map((p) => {
    const { bbox, properties } = p;
    const { href } = properties.links.related[0];

    const colormap = encodeURIComponent(JSON.stringify({
      1: '#fff',
      2: '#fff',
      3: '#fff',
      4: '#fff',
      5: '#fff',
      6: '#fff',
      7: '#fff',
    }));

    const expression = encodeURIComponent('b2+b3+b4+b5+b6+b12+b13');
    const url = encodeURIComponent(href);
    const baseUrl = `${titilerUrl}/cog/tiles/{z}/{x}/{y}@2x.png`

    const bounds = [[bbox[1], bbox[0]], [bbox[3], bbox[2]]];
    return L.tileLayer.canvas(`${baseUrl}?url=${url}&expression=${expression}&colormap=${colormap}`, {
      bounds,
      opacity: 1,
    }).setZIndex(4);
  });
}

export { createCloudLayers };