/* eslint-disable max-lines */
import { useState } from 'react';
// import { useOrderValidation, useUserOrganisation } from '@services/query.service';

import Button from '@library/Button/Button';

import Checkbox from '@App/components/Checkbox/Checkbox';

function TilesContent({ validationPayload }) {
  return (
    <>
      {validationPayload.collectionIds && (
      <>
        <h3 className="order-popup__title">Selected water quality indicators:</h3>
        <ul className="order-popup__list">
          {validationPayload.collectionIds.map((collection) => {
            return (
              <li key={collection} className="order-popup__list__item">
                <span>{collection}</span>
              </li>
            )
          })}
        </ul>
      </>
      )}
      <h3 className="order-popup__title">Selected tiles:</h3>
      <ul className="order-popup__list">
        {validationPayload.tiles.map(({ tileId, dates }) => {
          const options = { day: '2-digit', month: 'short', year: '2-digit' };

          return (
            <li key={tileId} className="order-popup__list__item">
              <span>{tileId}</span>
              {`: ${dates.map(date => new Date(date)).sort((a, b) => b - a).reverse()
                .map(date => `${date.toLocaleDateString('en-GB', options).split(' ').join(' ')}`)
                .join(', ')
              }`}
            </li>
          )
        })}
      </ul>
    </>
  );
}

function TimeSeriesContent({ validationPayload }) {
  return (
    <>
      <p>{`Name: ${validationPayload.desc}`}</p>
      <p>
        {`Surface: ${(validationPayload.area / 1000000).toFixed(2)} km²`}
      </p>
    </>
  );
}

function OrderValidation({
  closeAction,
  onConfirmOrder,
  validationPayload,
  orderType,
}) {
  const [timeSeriesCheck, setTimeSeriesCheck] = useState(false);
  // const { data, isLoading, error } = useOrderValidation(orderType, validationPayload);
  // const { 
  //   data: userOrganisation, 
  //   isLoading: isLoadingUserOrganisation,
  // } = useUserOrganisation(true, 0);

  // if (isLoading || isLoadingUserOrganisation) {
  //   return <div className="order-popup is-loading" />;
  // }

  // if (error) {
  //   return (
  //     <div className="order-popup__error">
  //       {'We cannot process your order. '}
  //       {error?.response?.data?.message || 'Unexpected error'}
  //     </div>
  //   );
  // }

  // const currentBalance = userOrganisation.receivedCredit - userOrganisation.spentCredit;
  // const balanceAfter = currentBalance - data.totalCost;
  // const isNazka = userOrganisation.code === 'NAZKA';

  return (
    <div className="order-popup">
      <div className="order-popup__content">
        {orderType !== 'ON DEMAND TIME SERIES' ? (
          <TilesContent validationPayload={validationPayload} />
        ) : (
          <TimeSeriesContent validationPayload={validationPayload} />
        )}
      </div>
      {/* <h3
        className={
          'order-popup__title order-popup__title--cost'
          + `${!isNazka && balanceAfter < 0 ? ' order-popup__title--error' : ''}`
        }
      >
        {`Cost:`}
        {' '}
        {data.totalCost}
      </h3> */}
      {orderType === 'ON DEMAND TIME SERIES' && (
        <Checkbox 
          className="order-popup__checkbox"
          label="I acknowledge that I cannot change the shape of the waterbody after creation."
          checked={timeSeriesCheck}
          onChange={(_e, isChecked) => {
            setTimeSeriesCheck(isChecked);
          }}
        />
      )}
      <div className="order-popup__buttons">
        <Button
          classType="primary"
          // disabled={(!isNazka && balanceAfter < 0) 
          //  || (orderType === 'ON DEMAND TIME SERIES' ? !timeSeriesCheck : false)}
          onClick={onConfirmOrder}
          className="order-popup__buttons__button"
        >
          confirm
        </Button>
        <Button onClick={closeAction} className="order-popup__buttons__button">
          cancel
        </Button>
      </div>
      {/* <div className="order-popup__footer-text">
        <p
          className={
            'order-popup__footer-text__current-balance'
            + `${!isNazka && balanceAfter < 0 ? '--too-low' : ''}`
          }
        >
          Current balance for
          {' '}
          {userOrganisation.name}
          :
          {' '}
          <span>{isNazka ? <>&infin;</> : currentBalance}</span>
        </p>
        {(isNazka || balanceAfter >= 0) && (
          <p>
            New balance after request:
            {' '}
            {isNazka ? <>&infin;</> : balanceAfter}
          </p>
        )}
      </div> */}
    </div>
  );
}

export default OrderValidation;