function getNextAndPrevDate(date, dates) {
  if (!date) {
    return {
      next: undefined,
      prev: undefined,
    };
  }

  const index = dates.findIndex(e => e === new Date(date).toDateString());
  return {
    prev: index + 1 < dates.length ? dates[index + 1] : undefined,
    next: index - 1 >= 0 ? dates[index - 1] : 0,
  };
}

export {
  getNextAndPrevDate,
};