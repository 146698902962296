import { useQuery, keepPreviousData } from '@tanstack/react-query';
import axios from 'axios';
import { useMemo } from 'react';

function useDateGroups(dates, cloudCover) {
  return useMemo(
    () => {
      const defaultValue = { highlightedDays: [], cloudyDays: [], allDays: [] };

      if (!dates) {
        return defaultValue;
      }

      return dates.reduce((acc, curr) => {
        if (curr.minCloudPercentage <= cloudCover) {
          acc.highlightedDays.push(curr.date);
        } else {
          acc.cloudyDays.push(curr.date);
        }

        acc.allDays.push(curr.date);

        return acc;
      }, defaultValue);
    },
    [dates, cloudCover],
  );
}

function useExploreDates(mapBounds, cloudCover, start, end) {
  const { data } = useQuery({
    queryKey: ['explore', 'dates', mapBounds, start, end],
    queryFn: async () => {
      const { data: availableDates } = await axios.get(`/api/catalogue/TERRASCOPE_S2_RHOW_V1/dates?bbox=${mapBounds.join(',')}&start=${start}&end=${end}`);

      return availableDates;
    },
    enabled: !!mapBounds,
    placeholderData: keepPreviousData,
  });

  return useDateGroups(data, cloudCover)
}

export {
  useExploreDates,
  useDateGroups,
};