/* eslint-disable max-lines */
import L from 'leaflet';
import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';

import 'leaflet-draw';
import 'leaflet-measure-path/leaflet-measure-path';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-measure-path/leaflet-measure-path.css';

import tilesJson from '@App/data/15tiles-tanganyika-kivu-with-tileid.json';

import {
  style, styleActive, styleDrawRectangle, styleActiveDrawRectangle, stylePartOfRectangle,
} from '../download-map.constants';

import { getTilesInDrawnRectangle } from './select-tiles-within-custom-area.utils';

import { setDrawnRectangle, setSelectedTiles } from '../../download.slice';

function SelectTilesWithinCustomArea({ map }) {
  const dispatch = useDispatch();
  const selectionType = useSelector(state => state.download.selectionType);
  const drawnRectangle = useSelector(state => state.download.drawnRectangle);
  const selectedTiles = useSelector(state => state.download.selectedTiles);
  const drawnRectangleClip = useSelector(state => state.download.drawnRectangleClip);

  const [drawInstance, setDrawInstance] = useState();
  const [customRectangleLayer, setCustomRectangleLayer] = useState();

  const availableTilesLayer = useRef(
    L.geoJSON(tilesJson, { ...style, style: { interactive: false } }),
  );
  const activeTilesLayer = useRef(
    L.geoJSON([], { ...styleActive, style: { interactive: false } }),
  );

  useEffect(() => {
    async function handleCustomAreaChange(layer) {
      const { overlappingTiles } = getTilesInDrawnRectangle(layer.toGeoJSON());
      
      const overlappingTilesWithDates = overlappingTiles;

      dispatch(setSelectedTiles(overlappingTilesWithDates));
    }

    if (map) {
      const rectangleStyle = drawnRectangleClip ? styleActiveDrawRectangle : styleDrawRectangle;
      const rectangleDrawer = new L.Draw.Rectangle(
        map, 
        { shapeOptions: rectangleStyle, showArea: false },
      );
      setDrawInstance(rectangleDrawer);

      map.on(
        `${L.Draw.Event.CREATED} ${L.Draw.Event.EDITMOVE} ${L.Draw.Event.EDITRESIZE}`, 
        debounce(({ layer }) => {
          setCustomRectangleLayer(layer);
          dispatch(setDrawnRectangle(layer.toGeoJSON()));
          handleCustomAreaChange(layer);

          layer.editing.enable();
          layer.bringToFront();
        }, 100),
      );
    }

    return () => {
      if (map) {
        map.off(L.Draw.Event.CREATED);
        map.off(`${L.Draw.Event.CREATED} ${L.Draw.Event.EDITMOVE} ${L.Draw.Event.EDITRESIZE}`);
      }
    };
  }, [map, drawnRectangleClip, dispatch, customRectangleLayer]);

  useEffect(() => {
    if (!map || !drawnRectangle) return;
    if (JSON.stringify(customRectangleLayer?.toGeoJSON()) !== JSON.stringify(drawnRectangle)) {
      const coords = drawnRectangle.geometry.coordinates[0];
      const bounds = L.latLngBounds(coords.map(coord => [coord[1], coord[0]]));
      const rectangleStyle = drawnRectangleClip ? styleActiveDrawRectangle : styleDrawRectangle;
      const rectangle = L.rectangle(bounds, { ...rectangleStyle });
      if (selectionType === 'draw-selection') rectangle.addTo(map);
      rectangle.editing.enable();
      setCustomRectangleLayer(rectangle);
    }
  }, [drawnRectangleClip, customRectangleLayer, drawnRectangle, selectionType, map]);

  useEffect(() => {
    if (!map || !drawInstance) return;
    if (selectionType === 'draw-selection') {
      if (customRectangleLayer) {
        map.addLayer(customRectangleLayer);
        
        customRectangleLayer.showMeasurements();
      } else {
        drawInstance.enable();
      }
    } else {
      drawInstance.disable();
      if (customRectangleLayer) {
        map.removeLayer(customRectangleLayer);
      }
    }
  }, [map, selectionType, drawInstance, customRectangleLayer]);

  useEffect(() => {
    activeTilesLayer.current.clearLayers();
    activeTilesLayer.current.addData(selectedTiles['draw-selection']);
    activeTilesLayer.current.setStyle(drawnRectangleClip ? stylePartOfRectangle : styleActive);

    availableTilesLayer.current.clearLayers();
    const availableTileIds = selectedTiles['draw-selection'].map(f => f.properties.tileid);
    availableTilesLayer.current.addData(
      tilesJson.features.filter(t => !availableTileIds.includes(t.properties.tileid)),
    );
  }, [selectedTiles, drawnRectangleClip]);

  // SHOW/HIDE TILE FEATURES
  useEffect(() => {
    if (!map) return;
    if (selectionType === 'draw-selection') {
      availableTilesLayer.current.addTo(map);
      activeTilesLayer.current.addTo(map);
      activeTilesLayer.current.bringToFront();
    } else {
      map.removeLayer(availableTilesLayer.current);
      map.removeLayer(activeTilesLayer.current);
    }
  }, [map, selectionType]);

  useEffect(() => {
    if (drawInstance) {
      drawInstance.setOptions({ shapeOptions: drawInstance ? styleActive : styleDrawRectangle });
      if (customRectangleLayer) {
        customRectangleLayer.setStyle(drawnRectangleClip 
          ? styleActiveDrawRectangle : styleDrawRectangle);
      }
    }
  }, [drawInstance, drawnRectangleClip, customRectangleLayer]);
  
  const rectangleElement = document.querySelector('path.leaflet-interactive[stroke="#333"]');
  if (rectangleElement) rectangleElement.parentNode.appendChild(rectangleElement);
  setTimeout(() => {
    const el = document.querySelector('path.leaflet-interactive[stroke="#333"]');
    if (el) el.parentNode.appendChild(el);
  }, 100);

  return null;
}

export default SelectTilesWithinCustomArea;