import { centroid, union, featureCollection } from '@turf/turf';

import tilesJson from '@App/data/15tiles-tanganyika-kivu-with-tileid.json';

function createTileLayers(products) {
  if (!products) return [];

  const availableTiles = products.map(product => product.id.match(/(?:[^_]*_){5}([^_]*)/)[1]);
  const tilesFeatures = tilesJson.features.filter(tile => (
    availableTiles.includes(tile.properties.tileid)
  ));

  if (!tilesFeatures.length) return [];

  const centroidMarkers = tilesFeatures.map((f) => {
    const markerEl = document.createElement('div');
    const textEl = document.createElement('span');
    textEl.innerHTML = f.properties.tileid;
    markerEl.appendChild(textEl);
    const label = L.divIcon({ 
      className: 'extra-layers__tile-label',
      html: markerEl,
      iconSize: 25,
    });
    const marker = new L.Marker(
      centroid(f).geometry.coordinates.reverse(), 
      { icon: label },
    );
    return marker;
  });

  let unionFeature = tilesFeatures[0];

  for (let i = 1; i < tilesFeatures.length; i += 1) {
    unionFeature = union(featureCollection([unionFeature, tilesFeatures[i]]));
  }

  const tilesLayer = L.geoJSON({
    type: 'FeatureCollection',
    features: tilesFeatures,
  }, {
    color: '#333',
    weight: 1,
    opacity: 0.2,
    fillOpacity: 0,
  }).setZIndex(4);

  const unionLayer = L.geoJSON({
    type: 'FeatureCollection',
    features: [unionFeature],
  }, {
    color: '#000',
    weight: 2,
    opacity: 0.2,
    fillOpacity: 0,
  }).setZIndex(5);

  return [unionLayer, tilesLayer, ...centroidMarkers];
}

export { createTileLayers };