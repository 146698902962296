const baseUrl = 'https://roda.sentinel-hub.com/sentinel-s2-l1c/tiles';

function getPreviewUrl(date, tileId) {
  const numberPart = tileId.slice(0, 2);
  const firstLetter = tileId.slice(2, 3);
  const finalTwoLetters = tileId.slice(3, 5);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${baseUrl}/${numberPart}/${firstLetter}/${finalTwoLetters}/${year}/${month}/${day}/0/preview.jpg`;
}

function filterSelectableDates(dates, selectedDates, date) {
  const dateString = date.toDateString();
  const dateStrings = dates.map(d => d.toDateString());

  const selectableDates = dateStrings.filter(d1 => (
    d1 === dateString || !selectedDates.find(d2 => d2.toDateString() === d1)
  ));

  return selectableDates;
}

export {
  getPreviewUrl,
  filterSelectableDates,
};