/* eslint-disable no-param-reassign */
/* eslint-disable max-lines */
import './compare-control.styl';

let mapWasDragEnabled
let mapWasTapEnabled

function getRangeEvent(rangeInput) {
  return 'oninput' in rangeInput ? 'input' : 'change'
}

function cancelMapDrag() {
  mapWasDragEnabled = this._map.dragging.enabled()
  mapWasTapEnabled = this._map.tap && this._map.tap.enabled()
  this._map.dragging.disable()
  if (this._map.tap) this._map.tap.disable()
}

function uncancelMapDrag(e) {
  this._refocusOnMap(e)
  if (mapWasDragEnabled) {
    this._map.dragging.enable()
  }
  if (mapWasTapEnabled) {
    this._map.tap.enable()
  }
}

// convert arg to an array - returns empty array if arg is undefined
function asArray(arg) {
  if (arg === 'undefined') return [];
  return Array.isArray(arg) ? arg : [arg];
}

function noop() {}

function updateClipStyle(map, layer, value = '') {
  if (!map || !map.hasLayer(layer)) return;

  if (layer.getContainer) {
    layer.getContainer().style.clip = value;
  } else {
    layer.getPane().style.clip = value;
  }
}

L.Control.Compare = L.Control.extend({
  options: {
    thumbSize: 42,
    padding: 0,
  },

  initialize(options) {
    L.setOptions(this, options)
  },

  getPosition() {
    const rangeValue = this._range.value
    const offset = (0.5 - rangeValue) * (2 * this.options.padding + this.options.thumbSize)
    return this._map.getSize().x * rangeValue + offset
  },

  setPosition: noop,

  includes: L.Evented.prototype || L.Mixin.Events,

  addTo(map) {
    this.remove()
    this._map = map
    const container = L.DomUtil.create('div', 'leaflet-sbs', map._controlContainer)
    this._divider = L.DomUtil.create('div', 'leaflet-sbs-divider', container)
    this._leftInfo = L.DomUtil.create('div', 'leaflet-sbs-left-info', this._divider)
    this._leftInfoType = L.DomUtil.create('p', 'leaflet-sbs-left-info-type', this._leftInfo)
    this._leftInfoDate = L.DomUtil.create('p', 'leaflet-sbs-left-info-date', this._leftInfo)
    this._rightInfo = L.DomUtil.create('div', 'leaflet-sbs-right-info', this._divider)
    this._rightInfoType = L.DomUtil.create('p', 'leaflet-sbs-right-info-type', this._rightInfo)
    this._rightInfoDate = L.DomUtil.create('p', 'leaflet-sbs-right-info-date', this._rightInfo)
    const range = L.DomUtil.create('input', 'leaflet-sbs-range', container)
    range.type = 'range'
    range.min = 0
    range.max = 1
    range.step = 'any'
    range.value = 0.5
    range.style.paddingLeft = `${this.options.padding}px`
    range.style.paddingRight = `${this.options.padding}px`
    this._leftLayers = [];
    this._rightLayers = [];
    this._range = range;
    this._container = container;
    this._addEvents()
    this._updateClip()
    return this
  },

  remove() {
    if (!this._map) {
      return this
    }
    this._leftLayers.forEach((leftLayer) => {
      updateClipStyle(this._map, leftLayer);
    });

    this._rightLayers.forEach((rightLayer) => {
      updateClipStyle(this._map, rightLayer);
    });
    this._removeEvents()
    L.DomUtil.remove(this._container)

    this._map = null

    return this
  },

  setLeftLayers(leftLayers) {
    this._leftLayers = asArray(leftLayers)
    this._updateLayers()
    return this
  },

  setRightLayers(rightLayers) {
    this._rightLayers = asArray(rightLayers)
    this._updateLayers()
    return this
  },

  setLeftLayersNameAndDate(measurementType, date) {
    this._leftInfoType.innerText = measurementType;
    this._leftInfoDate.innerText = date;
  },

  setRightLayersNameAndDate(measurementType, date) {
    this._rightInfoType.innerText = measurementType;
    this._rightInfoDate.innerText = date;
  },

  _updateClip() {
    const map = this._map
    const nw = map.containerPointToLayerPoint([0, 0])
    const se = map.containerPointToLayerPoint(map.getSize())
    const clipX = nw.x + this.getPosition()
    const dividerX = this.getPosition()

    this._divider.style.left = `${dividerX}px`
    this.fire('dividermove', { x: dividerX })
    const clipLeft = `rect(${[nw.y, clipX, se.y, nw.x].join('px,')}px)`
    const clipRight = `rect(${[nw.y, se.x, se.y, clipX].join('px,')}px)`
    
    this._leftLayers.forEach((leftLayer) => {
      updateClipStyle(map, leftLayer, clipLeft);
    });

    this._rightLayers.forEach((rightLayer) => {
      updateClipStyle(map, rightLayer, clipRight);
    });
  },

  _updateLayers() {
    const prevLeft = this._leftLayer
    const prevRight = this._rightLayer
    this._leftLayer = null;
    this._rightLayer = null;
    this._leftLayers.forEach((layer) => {
      if (this._map.hasLayer(layer)) {
        this._leftLayer = layer
      }
    }, this);
    this._rightLayers.forEach((layer) => {
      if (this._map.hasLayer(layer)) {
        this._rightLayer = layer
      }
    }, this);
    if (prevLeft !== this._leftLayer) {
      if (prevLeft) this.fire('leftlayerremove', { layer: prevLeft })
      if (this._leftLayer) this.fire('leftlayeradd', { layer: this._leftLayer })
    }
    if (prevRight !== this._rightLayer) {
      if (prevRight) this.fire('rightlayerremove', { layer: prevRight })
      if (this._rightLayer) this.fire('rightlayeradd', { layer: this._rightLayer })
    }
    this._updateClip()
  },

  _isOnLeftSide(e) {
    const cursorIsOnLeft = this.getPosition() > e.containerPoint.x;
    if (this.cursorIsOnLeft !== cursorIsOnLeft) {
      this._map.fireEvent('comparesideswitch', { cursorIsOnLeft })
    }
    this.cursorIsOnLeft = cursorIsOnLeft;
  },

  _addEvents() {
    const range = this._range;
    const map = this._map;
    if (!map || !range) return;
    map.on('move', this._updateClip, this);
    map.on('layeradd layerremove', this._updateLayers, this);
    map.on('mousemove', this._isOnLeftSide, this);
    L.DomEvent.on(range, getRangeEvent(range), this._updateClip, this);
    L.DomEvent.on(range, 'touchstart', cancelMapDrag, this);
    L.DomEvent.on(range, 'touchend', uncancelMapDrag, this);
    L.DomEvent.on(range, 'mousedown', cancelMapDrag, this);
    L.DomEvent.on(range, 'mouseup', uncancelMapDrag, this);
  },

  _removeEvents() {
    const range = this._range;
    const map = this._map;
    if (range) {
      L.DomEvent.off(range, getRangeEvent(range), this._updateClip, this);
      L.DomEvent.off(range, 'touchstart', cancelMapDrag, this);
      L.DomEvent.off(range, 'touchend', uncancelMapDrag, this);
      L.DomEvent.off(range, 'mousedown', cancelMapDrag, this);
      L.DomEvent.off(range, 'mouseup', uncancelMapDrag, this);
    }
    if (map) {
      map.off('layeradd layerremove', this._updateLayers, this);
      map.off('move', this._updateClip, this);
      map.off('mousemove', this._isOnLeftSide, this);
    }
  },
})

L.control.compare = (leftLayers, rightLayers, options) => {
  return new L.Control.Compare(leftLayers, rightLayers, options)
}