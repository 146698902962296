/* eslint-disable max-lines */
import React, {
  useState, useRef, useCallback,
} from 'react';
import Portal from '@utils/Portal/Portal';
import { usePopper } from 'react-popper';
import { DateRange } from 'react-date-range';

import { useOnClickOutside } from '@services/hooks.service';

import { faArrowsLeftRight } from '@fortawesome/pro-regular-svg-icons';
import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import Button from '@library/Button/Button';
import Tooltip from '@App/components/Tooltip/Tooltip';
import Icon from '@library/Icon/Icon';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import './date-range-picker.styl';

function DateRangePicker(props) {
  const datePickerRef = useRef();

  const [showDateRangePicker, setShowDateRangePicker] = useState();
  const [noDatesAvailable, setNoDatesAvailable] = useState(false);
  const [dateSelection, setDateSelection] = useState([]);
  const [selectionRange, setSelectionRange] = useState();

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement);

  useOnClickOutside(datePickerRef, useCallback(() => {
    setShowDateRangePicker(false);
  }, []));

  function handleSelect({ selection }) {
    const dates = props.availableDates
      .filter(d => d <= selection.endDate && d >= selection.startDate);
    setDateSelection(dates);
    if (!dates.length) setNoDatesAvailable(true);
    else setNoDatesAvailable(false);
    setSelectionRange(selection);
  }

  const defaultSelectionRange = {
    startDate: new Date('2022-11-01'),
    endDate: new Date('2022-11-01'),
    key: 'selection',
  };

  return (
    <div
      className={`date-range-picker${props.className ? ` ${props.className}` : ''}${props.disabled ? ' disabled' : ''}`}
      id={props.id}
    >
      <Tooltip
        place="right"
        id="download_date_range_tootip"
        tooltip={props.tooltip ? (<p>{props.tooltip}</p>) : null}
      >
        <div
          className="date-range-picker__icon-combination"
          onClick={() => setShowDateRangePicker(!showDateRangePicker)}
          ref={setReferenceElement}
        >
          <Icon
            className="date-range-picker__icon-combination__icon"
            icon={faCalendar}
          />
          <Icon
            className="date-range-picker__icon-combination__icon"
            icon={faArrowsLeftRight}
          />
        </div>
      </Tooltip>
      {showDateRangePicker && (
        <Portal>
          <div
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...attributes.popper}
            className="date-range-picker__ancher"
            style={styles.popper}
            ref={setPopperElement}
          >
            <div
              ref={datePickerRef}
              className="date-range-picker__ancher__wrapper"
              style={{
                right: props.customOffeset || undefined,
                transform: props.customOffeset ? '' : 'translateX(-50%)',
                bottom: props?.ancherBottom ? 'auto' : '25px',
                top: props?.ancherBottom ? '5px' : 'auto',
              }}
            >
              <DateRange
                className="date-range-picker__calender"
                ranges={selectionRange ? [selectionRange] : [defaultSelectionRange]}
                onChange={v => handleSelect(v)}
                minDate={new Date('2022-11-01')}
                maxDate={new Date('2023-12-31')}
                rangeColors={['#4191E2']}
                showDateDisplay={false}
              />
              <div className="date-range-picker__custom-overlay">
                <div className="date-range-picker__custom-overlay__message">
                  {noDatesAvailable && (
                    <p>No data available in selected date range.</p>
                  )}
                </div>
                <div className="date-range-picker__custom-overlay__buttons">
                  <Button
                    className="date-range-picker__custom-overlay__buttons__button"
                    classType="primary"
                    disabled={!dateSelection.length}
                    onClick={() => {
                      setShowDateRangePicker(false);
                      props.onSubmit(selectionRange);
                      setSelectionRange(defaultSelectionRange);
                    }}
                  >
                    Select
                  </Button>
                  <Button
                    className="date-range-picker__custom-overlay__buttons__button"
                    onClick={() => {
                      setShowDateRangePicker(false);
                      setSelectionRange(defaultSelectionRange);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Portal>
      )}
    </div>
  );
}

export default DateRangePicker;