import { createRoot } from 'react-dom/client';

L.Control.BaseLayers = L.Control.extend({
  options: {
    position: 'bottomleft',
  },

  initialize(element, options) {
    L.Util.setOptions(this, options);
    this.element = element;
  },

  onAdd() {
    const container = L.DomUtil.create('div', 'leaflet-control-base-layers');
    const root = createRoot(container);

    root.render(this.element);

    return container;
  },
})

L.baselayers = (element, options) => {
  return new L.Control.BaseLayers(element, options); 
}