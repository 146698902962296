/* eslint-disable react/jsx-props-no-spreading, max-lines */
import { useState, useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ClickAwayListener } from '@mui/material';
import { DatePicker as MaterialDatePicker, PickersDay } from '@mui/x-date-pickers';
import { faCalendar } from '@fortawesome/pro-light-svg-icons';

import IconButton from '@library/IconButton/IconButton';

import 'dayjs/locale/en-gb';

import './date-picker.styl';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { getNextAndPrevDate } from './date-picker.utils';

function DatePicker(props) {
  const {
    greyedOutDays,
    highlightedDays,
    onChange,
    value,
    placeholder = null,
    externalOpenToggle,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [nextDate, setNextDate] = useState();
  const [prevDate, setPrevDate] = useState();

  useEffect(() => {
    const { next, prev } = getNextAndPrevDate(value, highlightedDays);
    setNextDate(next);
    setPrevDate(prev);
  }, [value, highlightedDays]);

  useEffect(() => {
    if (externalOpenToggle || externalOpenToggle === false) {
      setIsOpen(externalOpenToggle);
    }
  }, [externalOpenToggle]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <ClickAwayListener onClickAway={() => !externalOpenToggle && setIsOpen(false)}>
        <div>
          <MaterialDatePicker
            {...props}
            value={value || null}
            onChange={newValue => onChange(newValue.$d.toISOString())}
            open={isOpen}
            onAccept={() => setIsOpen(false)}
            inputFormat={'d MMMM yyyy'.toUpperCase()}
            disableMaskedInput
            disableHighlightToday
            renderInput={({
              inputRef, inputProps,
            }) => {
              return (
                <div className="date-picker">
                  <input
                    className="date-picker__input"
                    ref={inputRef}
                    {...inputProps}
                    placeholder={placeholder}
                  />
                  <div className="date-picker__controls">
                    <IconButton
                      title="Previous date"
                      className="date-picker__controls__button date-picker__controls__button--prev"
                      icon={faChevronLeft}
                      iconColor="#000"
                      onClick={() => onChange(new Date(`${prevDate} UTC`).toISOString())}
                      disabled={!prevDate}
                    />
                    <IconButton
                      title="Select a date"
                      className="date-picker__controls__button"
                      icon={faCalendar}
                      iconColor="#000"
                      onClick={() => setIsOpen(!isOpen)}
                    />
                    <IconButton
                      title="Next date"
                      className="date-picker__controls__button date-picker__controls__button--next"
                      icon={faChevronRight}
                      iconColor="#000"
                      onClick={() => onChange(new Date(`${nextDate} UTC`).toISOString())}
                      disabled={!nextDate}
                    />
                  </div>
                </div>
              )
            }}
            renderDay={(day, selected, DayProps) => {
              const { key, ...dProps } = DayProps;
              const dateString = new Date(day).toDateString();
              if (highlightedDays && highlightedDays.includes(dateString)) {
                return (
                  <div title="Images available" key={key}>
                    <PickersDay
                      {...dProps}
                      key={key}
                      className="date-picker__selector__day date-picker__selector__day--highlighted"
                    />
                  </div>
                )
              }
              if (greyedOutDays && greyedOutDays.includes(dateString)) {
                return (
                  <div title="Too cloudy" key={key}>
                    <PickersDay
                      {...dProps}
                      key={key}
                      className="date-picker__selector__day date-picker__selector__day--greyed"
                    />
                  </div>
                );
              }
              return <PickersDay {...DayProps} key={key} className="date-picker__selector__day" />
            }}
          />
        </div>
      </ClickAwayListener>
    </LocalizationProvider>
  )
}

export default DatePicker;