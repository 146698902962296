import { createSlice } from '@reduxjs/toolkit';
import { MEDIA_BREAKPOINT_ENTRIES } from './app-window.constants';

const initialState = {
  resizeProps: {
    mediaBreakpoint: MEDIA_BREAKPOINT_ENTRIES[
      MEDIA_BREAKPOINT_ENTRIES
        .findIndex(entry => (entry[0] > window.innerWidth)) - 1 
    ][1],
  },
}

const appWindow = createSlice({
  name: 'appWindow',
  initialState,
  reducers: {
    updateResizeProps: (state) => {
      state.resizeProps = {
        mediaBreakpoint: MEDIA_BREAKPOINT_ENTRIES[
          MEDIA_BREAKPOINT_ENTRIES
            .findIndex(entry => (entry[0] > window.innerWidth)) - 1
        ][1],
      }
    },
  },
})

export const { updateResizeProps } = appWindow.actions

export default appWindow.reducer