import { NavLink } from 'react-router-dom';
import routes from '@App/app.routes';
import { Icon } from '@nazka/nazka.mapframe.components';

import './navbar.styl';

function Navbar() {
  return (
    <div className="navbar">
      <NavLink className="navbar__brand" to="/">
        <img className="navbar__brand__image" src="/img/logo/explore-logo.svg" alt="Explore VN logo" />
      </NavLink>
      <div className="navbar__menu">
        <div className="navbar__menu__section navbar__menu__section--top">
          {routes.filter(r => r.navPosition === 'top').map((route) => {
            return (
              <li
                key={route.name}
                className={`navbar__menu__section__item${route.disabled ? ' disabled' : ''}`}
              >
                <NavLink
                  to={route.path}
                  className={({ isActive }) => (
                    `navbar__menu__section__item__link${isActive ? ' navbar__menu__section__item__link--active' : ''}`
                  )}
                >
                  <Icon
                    className="navbar__menu__section__item__link__icon"
                    icon={route.icon}
                  />
                  <span
                    className="navbar__menu__section__item__link__text"
                  >
                    {route.name}
                  </span>
                </NavLink>
              </li>
            );
          })}
        </div>
        <div className="navbar__menu__section navbar__menu__section--bottom">
          {routes.filter(r => r.navPosition === 'bottom').map((route) => {
            return (
              <li
                key={route.name}
                className={`navbar__menu__section__item${route.disabled ? ' disabled' : ''}`}
              >
                <NavLink
                  to={route.path}
                  className="navbar__menu__section__item__link"
                >
                  <Icon
                    className="navbar__menu__section__item__link__icon"
                    icon={route.icon}
                  />
                  <span
                    className="navbar__menu__section__item__link__text"
                  >
                    {route.name}
                  </span>
                </NavLink>
              </li>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
