/* eslint-disable import/no-cycle */
import groupBy from 'lodash.groupby';

import { generateUniqueId } from '@services/utils.service';
import { controls } from '../download-map.config';

import './SearchControl/search-control';
// import './BookmarkControl/bookmark-control';
// import './DownloadControl/download-control';

function reduceToMoreComponentImports(collapsibleControls) {
  const controlImports = [];
  const controlsPerPosition = groupBy(collapsibleControls, (curr) => {
    const { position } = curr;

    return position || 'topright';
  });

  Object.keys(controlsPerPosition).forEach((position) => {
    const controlImport = mapToComponentImport(controlsPerPosition[position]);

    controlImports.push(controlImport);
  });

  return controlImports;
}

/*  Exports
    ======================================================================================= */
function mapToComponentImport(control) {
  switch (control.identifier) {
    case 'search':
      return import('./SearchControl/SearchControl')
        .then(Control => ({ Component: Control.default, config: control }));
    case 'zoom':
      return import('./ZoomControl/ZoomControl')
        .then(Control => ({ Component: Control.default, config: control }));
    case 'scale':
      return import('./ScaleControl/ScaleControl')
        .then(Control => ({ Component: Control.default, config: control }));
    default:
      return null
  }
}

function controlImportToJsx(Control, map) {
  return Control && (
    <Control.Component
      key={generateUniqueId()}
      map={map}
      config={Control.config}
    />
  );
}

function fetchControls(map) {
  const defaultControls = controls.filter(curr => !curr.collapsed);
  const collapsedControls = controls.filter(curr => curr.collapsed);

  const controlImports = defaultControls.map(mapToComponentImport);

  if (collapsedControls.length) {
    controlImports.push(...reduceToMoreComponentImports(collapsedControls));
  }
  return Promise.all(controlImports)
    .then(Controls => Controls.map(Control => controlImportToJsx(Control, map)));
}

export {
  mapToComponentImport,
  controlImportToJsx,
  fetchControls,
}