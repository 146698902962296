import Checkbox from '@App/components/Checkbox/Checkbox';
import Radiogroup from '@App/components/Radiogroup/Radiogroup';

import { additionalLayerOptions } from '../../explore.constants';

import './extra-layers.styl';

function ExtraLayers(props) {
  const { 
    showAdditionalLayer, setShowAdditionalLayer, additionalLayer,
    setAdditionalLayer,
  } = props;
  
  return (
    <div className="extra-layers">
      <Checkbox
        name="checkbox-example"
        className="extra-layers__checkbox"
        label="Show extra information"
        checked={showAdditionalLayer}
        onChange={(e, isChecked) => setShowAdditionalLayer(isChecked)}
      />
      <Radiogroup
        name="additional-dynamic-options"
        className={`extra-layers__radio-group${!showAdditionalLayer ? ' disabled' : ''}`}
        radios={additionalLayerOptions}
        selectedValue={additionalLayer}
        onChange={o => setAdditionalLayer(o)}
        showTooltips
      />
    </div>
  )
}

export default ExtraLayers;