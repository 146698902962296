const layers = [
  {
    id: 'maptiler-base-layer',
    urlTemplate: `https://api.maptiler.com/maps/49f83cd3-3d43-416b-96cf-0a57338b904e/256/{z}/{x}/{y}@2x.png?key=${import.meta.env.VITE_MAPTILER_TOKEN}`,
    options: {
      attribution: 
        '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a>'
        + ' <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap</a>',
    },
  },
  {
    id: 'maptiler-street-labels',
    urlTemplate: `https://api.maptiler.com/maps/9dc68bef-9259-4f6e-bb9a-9d822d52de53/256/{z}/{x}/{y}.png?key=${import.meta.env.VITE_MAPTILER_TOKEN}`,
  },
  {
    id: 'mapbox-satellite-layer',   
    useOnlyWithLayerControl: true,
    urlTemplate: `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=${import.meta.env.VITE_MAPBOX_TOKEN}`,
    options: {
      attribution: 
        '<a href="https://www.mapbox.com/about/maps/" target="_blank">&copy; Mapbox</a>'
        + ' <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap</a>'
        + ' <a href="https://www.maxar.com/" target="_blank">&copy; Maxar</a>'
        + ' <a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a>'
        + ' <a href="http://mapbox.com/about/maps" class="leaflet-map__mapbox-logo" target="_blank" rel="noopener noreferrer">Mapbox</a>',
    },
  },
  {
    id: 'esri-satellite-layer',  
    useOnlyWithLayerControl: true,
    urlTemplate: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    options: {
      attribution: 
        'Source: Esri, Maxar, Earthstar Geographics, and the GIS User Community',
      maxNativeZoom: 17,
    },
  },
  {
    id: 'maptiler-satellite-labels',
    useOnlyWithLayerControl: true,
    urlTemplate: `https://api.maptiler.com/maps/f3507003-e174-41dd-b5e3-d37eed28ad92/256/{z}/{x}/{y}.png?key=${import.meta.env.VITE_MAPTILER_TOKEN}`,
    options: {
      attribution: '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a>',
    },
  },
];

export {
  layers,
}