import { useSelector } from 'react-redux';

import OrderPopup from '@App/components/OrderPopup/OrderPopup';

import { createValidationPayload, getOrderType } from './download-confirmation.utils';

function DownloadComfirmation({
  closeAction,
  selectedTilesWithDates,
}) {
  const drawnRectangle = useSelector(state => state.download.customAreaRectangle);
  const drawnRectangleClip = useSelector(state => state.download.drawnRectangleClip);
  const measurementSelection = useSelector(state => state.download.measurementSelection);

  const validationPayload = createValidationPayload(
    measurementSelection, 
    selectedTilesWithDates, 
    drawnRectangle,
  );

  const clip = drawnRectangleClip === 'clip';
  const mergeAndClip = drawnRectangleClip === 'mergeAndClip';

  return (
    <OrderPopup
      closeAction={closeAction}
      validationPayload={validationPayload}
      orderType={getOrderType(clip, mergeAndClip, selectedTilesWithDates)}
    />
  );
}

export default DownloadComfirmation;