import L from 'leaflet';
import 'leaflet-editable';
import { layers, controls } from './download-map.config';
import { addCanvasTileLayerExtension } from './download-map.utils';

/*  Exports
    ======================================================================================= */
function layerFactory(layer) {
  let newLayer = {};
  const {
    id,
    urlTemplate,
    options,
    extensions,
  } = layer;

  if (extensions) {
    L.TileLayer.Ext = L.TileLayer.extend(extensions);
    L.tileLayer.ext = (url, opt) => new L.TileLayer.Ext(url, opt);
    
    newLayer = L.tileLayer.ext(urlTemplate, options);
  } else {
    newLayer = L.tileLayer(urlTemplate, options);
  }

  newLayer.getMapframeId = () => id;
  
  return newLayer;
}

function mapFactory(mapEl) {
  addCanvasTileLayerExtension();
  const options = {
    zoomControl: false,
    attributionControl: false,
    center: [-5, 29],
    zoom: 7,
    minZoom: 7,
    maxBounds: [[-9.5, 26], [-0.5, 34]],
    editable: true,
  };

  const map = L.map(mapEl, options);

  const originalInvalidateSize = map.invalidateSize;
  map.invalidateSize = function invalidateSize(userOptions = {}) {
    const newOptions = { pan: false, ...userOptions };
    return originalInvalidateSize.call(this, newOptions);
  };

  L.control.attribution().setPrefix(
    '<a href="https://nazka.be" rel="noopener" target="_blank">&copy; Nazka Mapps</a>'
    + ' <a href="https://vito.be" rel="noopener" target="_blank">&copy; VITO</a>',
  ).addTo(map);
  
  return map;
}

function addLayersFromConfigToMap(leafletMap) {
  const layersControl = controls.find(control => (control.identifier && (control.identifier === 'baselayers')));
  if (!layersControl) {
    layers.filter(layer => (layersControl || !layer.useOnlyWithLayerControl)).forEach((layer) => {
      layerFactory(layer).addTo(leafletMap);
    });
  }

  return leafletMap;
}

export {
  layerFactory,
  mapFactory,
  addLayersFromConfigToMap,
};