import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  measurementTypes: [],
  cloudCoverage: 80,
  focusFeature: undefined,
  drawnRectangle: undefined,
  drawnRectangleClip: false,
  selectionType: 'click-selection',
  selectedTiles: {
    'click-selection': [],
    'draw-selection': [],
  },
}

const appWindow = createSlice({
  name: 'download',
  initialState,
  reducers: {
    setMeasurementTypes: (state, action) => {
      state.measurementTypes = action.payload
    },
    setCloudCoverage: (state, action) => {
      state.cloudCoverage = action.payload
    },
    setSelectionType: (state, action) => {
      state.selectionType = action.payload
    },
    setDrawnRectangleClip: (state, action) => {
      state.drawnRectangleClip = action.payload
    },
    setSelectedTiles: (state, action) => {
      const prevTiles = state.selectedTiles[state.selectionType];
      const nextTiles = action.payload;

      const updatedTiles = nextTiles.map((nextTile) => {
        const matchingPrevTile = prevTiles.find(prevTile => (
          prevTile.properties.tileid === nextTile.properties.tileid
        ));
        return matchingPrevTile || nextTile;
      });

      state.selectedTiles = {
        ...state.selectedTiles,
        [state.selectionType]: updatedTiles,
      };
    },
    updateSelectedTiles: (state, action) => {
      const tiles = state.selectedTiles[state.selectionType];
      const tileUpdates = action.payload;

      const updatedTiles = tiles.map((tile) => {
        const updatedTile = tileUpdates.find(t => (
          t.properties.tileid === tile.properties.tileid
        ));
        return updatedTile || tile;
      });

      state.selectedTiles = {
        ...state.selectedTiles,
        [state.selectionType]: updatedTiles,
      };
    },
    removeTile: (state, action) => {
      const updatedTiles = state.selectedTiles[state.selectionType]
        .filter(tile => tile.properties.tileid !== action.payload.properties.tileid);
      state.selectedTiles = {
        ...state.selectedTiles,
        [state.selectionType]: updatedTiles,
      };
    },
    setDrawnRectangle: (state, action) => {
      state.drawnRectangle = action.payload
    },
    focusMapOnFeature: (state, action) => {
      state.focusFeature = action.payload
    },
  },
})

export const { 
  setMeasurementTypes, setCloudCoverage, setSelectionType, setSelectedTiles, setDrawnRectangle, 
  focusMapOnFeature, setDrawnRectangleClip, updateSelectedTiles, removeTile,
} = appWindow.actions

export default appWindow.reducer