import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import PopupWindow from '@App/components/PopupWindow/PopupWindow';

// import { placeOrder } from '@services/order.service';

import OrderConfirmation from './OrderConfirmation/OrderConfirmation';
import OrderValidation from './OrderValidation/OrderValidation';

import { getPopupTitle } from './order-popup.utils';

import './order-popup.styl';

function OrderPopup({
  closeAction,
  validationPayload,
  orderType,
}) {
  const queryClient = useQueryClient();

  // const orderMutation = useMutation({
  //   mutationFn: () => placeOrder(orderType, validationPayload),
  //   onSuccess: () => {
  //     queryClient.invalidateQueries({
  //       predicate: ({ queryKey }) => {
  //         const isUserQuery = queryKey[0] === 'user';

  //         if (orderType !== 'ON DEMAND TIME SERIES') {
  //           return isUserQuery;
  //         }

  //         return isUserQuery || queryKey.find(key => key === 'waterbodies');
  //       },
  //     });
  //   },
  // });

  return (
    <PopupWindow
      closeAction={closeAction}
      title={getPopupTitle(orderMutation)}
    >
      {/* {(orderMutation.data || orderMutation.isLoading || orderMutation.isError) ? (
        <OrderConfirmation
          order={orderMutation}
          orderType={orderType}
        />
      ) : ( */}
      <OrderValidation
        closeAction={closeAction}
        onConfirmOrder={orderMutation.mutate}
        validationPayload={validationPayload}
        orderType={orderType}
      />
      {/* )} */}
    </PopupWindow>
  );
}

export default OrderPopup;