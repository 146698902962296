import { useEffect, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useProducts } from '@utils/QueryClient/products.queries';
import { createTileLayers } from './tile-footprints-layer.utils';

function TileFootprintsLayer({ map }) {
  const additionalLayer = useSelector(state => state.explore.additionalLayer);
  const date = useSelector(state => state.explore.date);
  const layers = useRef();

  const nextDay = useMemo(() => {
    if (!date) return null;
    const d = new Date(date);
    d.setDate(d.getDate() + 1);
    return d.toISOString();
  }, [date]);

  const { data } = useProducts(
    { start: date, end: nextDay }, 
    'TERRASCOPE_S2_RHOW_V1', 
    !!(date && nextDay),
  );

  useEffect(() => {
    if (map && additionalLayer === 'TILE_FOOTPRINTS' && data?.features) {
      layers.current = createTileLayers(data.features);
      if (layers?.current?.length) {
        layers.current.forEach(layer => layer && layer?.addTo(map));
      }
    }

    return () => {
      if (layers?.current?.length) {
        layers.current.forEach(layer => layer?.remove());
      }
    };
  }, [map, additionalLayer, data]);

  return null;
}

export default TileFootprintsLayer;