import { useEffect, useRef, useCallback } from 'react';
import Close from '@library/Close/Close';
import { useOnClickOutside } from '@services/hooks.service';

import './popup-window.styl';

function PopupWindow({ title, children, closeAction }) {
  const windowRef = useRef();

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) closeAction();
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [closeAction]);

  useOnClickOutside(windowRef, useCallback(() => {
    closeAction();
  }, [closeAction]));

  return (
    <div className="popup-window">
      <div className="popup-window__window" ref={windowRef}>
        <div className="popup-window__window__header">
          <h2>{title || ''}</h2>
          <Close onClick={closeAction} />
        </div>
        <div className="popup-window__window__body">
          {children}
        </div>
      </div>
    </div>
  )
}

export default PopupWindow;