import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

function useProducts(options, product = 'TERRASCOPE_S2_RHOW_V1', enabled = true) {
  return useQuery({
    queryKey: ['products', product, options],
    queryFn: async () => {
      const searchParams = new URLSearchParams(options);

      const { data } = await axios.get(`/api/catalogue/${product}/products?${searchParams.toString()}`);

      return data;
    },
    enabled,
  });
}

export {
  useProducts,
};