import { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { mapFactory, addLayersFromConfigToMap } from './explore-map.factory';
import { setMapBounds } from '../explore.slice';

import Controls from './Controls/Controls';
import DataLayers from './DataLayers/DataLayers';

import 'leaflet/dist/leaflet.css';
import './explore-map.styl';

function ExploreMap() {
  const dispatch = useDispatch();
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);

  useEffect(() => {
    const mapInstance = mapFactory(mapRef.current);

    function updateMapBounds() {
      const bounds = mapInstance.getBounds();
      const northEast = bounds.getNorthEast();
      const southWest = bounds.getSouthWest();

      dispatch(setMapBounds([southWest.lng, southWest.lat, northEast.lng, northEast.lat]));
    }

    updateMapBounds();
    setMap(mapInstance);

    mapInstance.on('moveend', updateMapBounds);

    return () => mapInstance.remove();
  }, [dispatch]);

  useEffect(() => {
    if (!map) return;
    addLayersFromConfigToMap(map);
  }, [map]);

  return (
    <div ref={mapRef} className="leaflet-map explore-map">
      <Controls map={map} />
      <DataLayers map={map} />
    </div>
  );
}

export default ExploreMap;