function getPreviewCloudPercentage(highlightedPreview, tilesWithDates) {
  if (!highlightedPreview || !tilesWithDates) {
    return null;
  }

  const tile = tilesWithDates.find((ti) => {
    return ti.tileid === highlightedPreview.tileid;
  });

  if (!tile.availableDates) {
    return null;
  }

  return tile.availableDates.find((
    d => d?.date.getTime() === highlightedPreview?.date.getTime()
  ))?.minCloudPercentage;
}

function getDatePickerDates(tilesWithDates) {
  if (!tilesWithDates) {
    return [];
  }

  return tilesWithDates.flatMap(({ dates, availableDates }) => {
    const nonNullDates = dates || [];
    const nonNullAvailableDates = availableDates || [];
    return [...nonNullDates, ...nonNullAvailableDates.map(({ date }) => date)];
  });
}

export {
  getPreviewCloudPercentage,
  getDatePickerDates,
};