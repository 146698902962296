function getPopupTitle(orderMutation) {
  if (orderMutation.isLoading) {
    return 'Placing your order...';
  }

  if (orderMutation.error) {
    return 'Order failed...';
  }

  if (orderMutation.data) {
    return 'Order successful';
  }

  return 'Your order';
}

export {
  getPopupTitle,
};