import { layers } from '@App/config/raster.layers';

const controls = [
  {
    identifier: 'search',
    position: 'topright',
  },
  {
    identifier: 'zoom',
    position: 'topright',
  },
  {
    identifier: 'scale',
    position: 'bottomright',
  },
]

export {
  layers,
  controls,
}