import IconButton from '@library/IconButton/IconButton';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

import './close.styl';

function Close(props) {
  return (
    <IconButton
      className={`close${props.className ? ` ${props.className}` : ''}`}
      icon={faTimes}
      iconColor={props.iconColor}
      onClick={props.onClick}
      ariaLabel="close datapanel"
      inert={props.inert}
    />
  );
}

export default Close;