import COOKIE_CONFIG from '@utils/Cookies/cookies.config';

/*  EXPORTS
    =============================================================== */
function initCookiesProps(t) {
  const {
    COOKIE_NAME = undefined,
    COOKIE_DOMAIN = undefined,
    COOKIE_PATH = undefined,
    EXPIRY_DATE = undefined,
    TITLE_TEXT = undefined,
    CATEGORIES = undefined,
    ALLOW_ESSENTIAL_ONLY_TEXT = undefined,
    ACCEPT_ALL_TEXT = undefined,
    SAVE_PREFERENCE_TEXT = undefined,
    POLICY_LINK = undefined, 
  } = COOKIE_CONFIG;

  const categories = CATEGORIES?.map((category) => {
    const { label, description } = category;
    
    return {
      ...category,
      label: t(label),
      description: t(description),
    }
  })

  return {
    cookieDomain: COOKIE_DOMAIN,
    cookieName: COOKIE_NAME,
    cookiePath: COOKIE_PATH,
    expires: EXPIRY_DATE,
    titleText: TITLE_TEXT && t(TITLE_TEXT),
    categories,
    allowEssentialOnlyText: ALLOW_ESSENTIAL_ONLY_TEXT && t(ALLOW_ESSENTIAL_ONLY_TEXT),
    acceptAllText: ACCEPT_ALL_TEXT && t(ACCEPT_ALL_TEXT),
    savePreferenceText: SAVE_PREFERENCE_TEXT && t(SAVE_PREFERENCE_TEXT),
    policyLink: POLICY_LINK && t(POLICY_LINK),
  }
}

export {
  initCookiesProps,
};