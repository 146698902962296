import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

function TrueColorImageLayer({ map }) {
  const showAdditionalLayer = useSelector(state => state.explore.showAdditionalLayer);
  const additionalLayer = useSelector(state => state.explore.additionalLayer);
  const date = useSelector(state => state.explore.date);
  const layer = useRef();

  useEffect(() => {
    if (map && date && showAdditionalLayer && additionalLayer === 'TERRASCOPE_S2_RHOW_V1') {
      const wmtsUrl = (
        'https://services.terrascope.be/wmts/v2?Service=WMTS&Request=GetTile&Format=image/png'
        + '&Version=1.0.0&tilematrixset=EPSG:3857&TileMatrix=EPSG:3857:{z}&TileCol={x}&TileRow={y}'
        + `&layer=${additionalLayer}&TIME=${date.split('T')[0]}&style=`
      );
      
      layer.current = L.tileLayer(wmtsUrl, {
        pane: 'additional-layers',
      }).addTo(map);
    }

    return () => {
      layer?.current?.remove();
    };
  }, [map, date, showAdditionalLayer, additionalLayer])

  return null;
}

export default TrueColorImageLayer;