import { useRef } from 'react';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { Icon } from '@nazka/nazka.mapframe.components';
import Tooltip from '@App/components/Tooltip/Tooltip';
import ImageTooltip from '@App/components/Tooltip/ImageTooltip/ImageTooltip';

import { generateUniqueId } from '@services/utils.service';

import './checkbox.styl';

function Checkbox(props) {
  const {
    label, name, checked, className, onChange, tooltip, disabled, color,
  } = props;
  const idRef = useRef(generateUniqueId());

  return (
    <div className={`checkbox ${className || ''}${disabled ? ' disabled' : ''}`}>
      <input
        id={idRef.current}
        className="checkbox__input"
        type="checkbox"
        name={name}
        checked={disabled ? false : !!checked}
        onChange={e => onChange(e, e.target.checked, e.target.name)}
      />
      <label htmlFor={idRef.current} className="checkbox__label">
        <span className="checkbox__label__box" style={{ borderColor: checked && color ? color : '' }}>
          <span className="checkbox__label__box__center" style={{ backgroundColor: color || '' }} />
        </span>
        <span className="checkbox__label__text">{ label }</span>
        <span />
      </label>
      {tooltip && (
        <Tooltip
          place="right"
          id={`${label}_tooltip`}
          tooltip={(<ImageTooltip text={tooltip.text} img={tooltip.img} url={tooltip.url} />)}
        >
          <Icon 
            icon={faInfoCircle} 
            className="checkbox__info"
            rootElProps={{ 
              'data-tooltip-id': `${label}_tooltip`,
            }}
          />
        </Tooltip>
      )}
    </div>
  );
}

export default Checkbox;