/* eslint-disable max-lines */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Portal from '@utils/Portal/Portal';
import Button from '@library/Button/Button';
import Checkbox from '@App/components/Checkbox/Checkbox';
import CustomTooltip from '@App/components/Tooltip/Tooltip';
import ImageTooltip from '@App/components/Tooltip/ImageTooltip/ImageTooltip'
import { Icon } from '@nazka/nazka.mapframe.components';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';

import { setDrawnRectangleClip } from '@App/pages/Download/download.slice';

import DownloadConfirmation from '../../DownloadConfirmation/DownloadConfirmation';

function Footer({ selectedTiles, measurementSelection, selectionType }) {
  const dispatch = useDispatch();
  const drawnRectangleClip = useSelector(state => state.download.drawnRectangleClip);

  const [noDataTypeSelectedError, setNoDataTypeSelectedError] = useState();
  const [noTileSelectedError, setNoTileSelectedError] = useState();
  const [tileswithNoDatesError, setTileswithNoDatesError] = useState();
  const [showConfirmationScreen, setShowConfirmationScreen] = useState(false);

  useEffect(() => {
    setNoDataTypeSelectedError(false);
  }, [measurementSelection]);

  useEffect(() => {
    setNoTileSelectedError(false);
  }, [selectedTiles]);

  return (
    <div className="download-form__footer">
      {(noDataTypeSelectedError || noTileSelectedError) && (
        <div className="download-form__error">
          Select at least one data type and one tile on the map.
        </div>
      )}
      {(!noDataTypeSelectedError && tileswithNoDatesError) && (
        <div className="download-form__error">
          Not all tiles have a date selected.
        </div>
      )}
      <div className="download-form__footer__button">
        <Button
          classType="primary"
          onClick={() => {
            const dataTypeSelected = measurementSelection.length;
            const tilesWithNoDate = !!selectedTiles.find(tile => !tile.dates.length);
            setNoDataTypeSelectedError(!dataTypeSelected);
            setNoTileSelectedError(!selectedTiles.length);
            setTileswithNoDatesError(tilesWithNoDate);
            if (dataTypeSelected && !tilesWithNoDate && selectedTiles.length) {
              setShowConfirmationScreen(true);
            }
          }}
          disabled={false}
        >
          Proceed
        </Button>
        {selectionType === 'draw-selection' && (
          <>
            <div className="download-form-footer__checkbox">
              <Checkbox
                label="Clip and merge"
                checked={drawnRectangleClip === 'mergeAndClip'}
                onChange={(_e, isChecked) => {
                  dispatch(setDrawnRectangleClip(isChecked ? 'mergeAndClip' : false));
                }}
                disabled={selectedTiles.length <= 1}
              />
              <CustomTooltip
                id="merge-and-clip-tooltip"
                tooltip={(
                  <ImageTooltip 
                    className="download-form-footer__checkbox__info__tooltip" 
                    text="When your selection overlaps multiple tiles, you can merge the different parts (paste them together). This is done using the gdalwarp program: when multiple values are available for a given raster cell, the last one is chosen."
                  />
                )}
                place="top"
              >
                <Icon 
                  icon={faInfoCircle} 
                  className="download-form-footer__checkbox__info"
                  rootElProps={{ 
                    'data-tooltip-id': 'merge-and-clip-tooltip',
                  }}
                />
              </CustomTooltip>
            </div>
            <div className="download-form-footer__checkbox">
              <Checkbox
                label="Clip"
                checked={drawnRectangleClip === 'clip'}
                onChange={(_e, isChecked) => {
                  dispatch(setDrawnRectangleClip(isChecked ? 'clip' : false));
                }}
                disabled={selectedTiles.length < 1}
              />
              <CustomTooltip
                id="clip-tooltip"
                tooltip={(
                  <ImageTooltip 
                    className="download-form-footer__checkbox__info__tooltip" 
                    text="Instead of downloading the full tile, download a clip (cut-out) of your selection." 
                  />
                )}
                place="top"
              >
                <Icon 
                  icon={faInfoCircle} 
                  className="download-form-footer__checkbox__info"
                  rootElProps={{ 
                    'data-tooltip-id': 'clip-tooltip',
                  }}
                />
              </CustomTooltip>
            </div>
          </>
        )}
      </div>
      {showConfirmationScreen && (
        <Portal>
          <DownloadConfirmation
            closeAction={() => setShowConfirmationScreen(false)}
            selectedTilesWithDates={selectedTiles}
            measurementSelection={measurementSelection}
          />
        </Portal>
      )}
    </div>
  );
}

export default Footer;