function addCanvasTileLayerExtension() {
  L.TileLayer.Canvas = L.TileLayer.extend({
    // Creates canvas tiles that unlike img tiles allow to get the colour 
    // of a specific point (see getImageData()), also performs much better
    createTile(coords, done) {
      let err;
      
      const tile = document.createElement('canvas');
      tile.setAttribute('class', 'canvas-tile');
      const ctx = tile.getContext('2d', { willReadFrequently: true });

      const { x: width, y: height } = this.getTileSize();
      tile.width = width * 2;
      tile.height = height * 2;

      const img = new Image();
      img.onload = () => {
        try {
          ctx.drawImage(img, 0, 0);
          tile.complete = true;
        } catch (e) {
          err = e;
        } finally {
          done(err, tile);
        }
      };
      const tileZoom = this._getZoomForUrl();
      img.src = Number.isNaN(tileZoom) ? '' : this.getTileUrl(coords);
      img.crossOrigin = 'Anonymous';
      return tile;
    },
  });

  L.tileLayer.canvas = function tileLayerCanvas(url, options) {
    return new L.TileLayer.Canvas(url, options);
  };
}

export { addCanvasTileLayerExtension };