const COOKIE_NAME = 'nazka-cookie-consent';
const COOKIE_DOMAIN = undefined; // https://github.com/js-cookie/js-cookie?tab=readme-ov-file#domain
const COOKIE_PATH = '/'; // https://github.com/js-cookie/js-cookie?tab=readme-ov-file#path
const EXPIRY_DATE = new Date();
EXPIRY_DATE.setMonth(EXPIRY_DATE.getMonth() + 6);

const TITLE_TEXT = 'This site uses cookies to ensure you get the best experience';

const CATEGORIES = [
  {
    id: 'essential',
    label: 'Essential cookies',
    description: 'These cookies are necessary for the good functioning and a good user experience of this website.',
    readOnly: true,
    required: true,
  },
  {
    id: 'analytical',
    label: 'Analytical cookies',
    description: 'These cookies are used to gather statistics on how and how much the website is being used. This information is anonymized and is only being used to improve the content of our website.',
    required: false,
  },
];

const ALLOW_ESSENTIAL_ONLY_TEXT = 'Allow only essential cookies';
const ACCEPT_ALL_TEXT = 'Accept all cookies';
const SAVE_PREFERENCE_TEXT = 'Save your preference';

const POLICY_LINK = "<a href='https://legal.nazka.be/docs/en/cookie-policy/' rel='noopener noreferrer' target='_blank'>Read more about our cookie policy.</a>";

export default {
  COOKIE_NAME,
  COOKIE_DOMAIN,
  COOKIE_PATH,
  EXPIRY_DATE,
  TITLE_TEXT,
  CATEGORIES,
  ALLOW_ESSENTIAL_ONLY_TEXT,
  ACCEPT_ALL_TEXT,
  SAVE_PREFERENCE_TEXT,
  POLICY_LINK,
};