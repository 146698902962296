import { useState, useEffect, useRef } from 'react';
import { Slider } from '@mui/material';
import { faCloud, faSunBright } from '@fortawesome/pro-regular-svg-icons';

import { Icon } from '@nazka/nazka.mapframe.components';

import './cloud-coverage-slider.styl';

function valueLabelFormat(value) {
  return `${Math.ceil(value)}%`;
}

function CloudCoverageSlider({
  min,
  max,
  value,
  setValue,
}) {
  const [inputIsFocused, setInputIsFocused] = useState(false);
  const input = useRef();
  
  useEffect(() => {
    if (inputIsFocused) input.current.select();
  }, [inputIsFocused]);

  return (
    <div className="cloud-coverage-slider">
      <div className="cloud-coverage-slider__slider">
        <Icon
          icon={faSunBright}
          size="18px"
        />
        <Slider
          min={min}
          max={max}
          value={value}
          valueLabelDisplay="off"
          valueLabelFormat={valueLabelFormat}
          getAriaValueText={valueLabelFormat}
          onChange={(_e, val) => setValue(val)}
          className="cloud-coverage-slider__slider__bar"
          sx={{
            '& .MuiSlider-thumb': {
              width: '13px',
              height: '13px',
              '&:hover, &.Mui-focusVisible, &.Mui-active': {
                boxShadow: '0px 0px 0px 4px rgba(25, 118, 210, 0.16)',
              },
            },
          }}
        />
        <Icon
          icon={faCloud}
          size="18px"
        />
      </div>
      <div className="cloud-coverage-slider__extra">
        <input
          ref={input}
          className="cloud-coverage-slider__extra__input"
          value={inputIsFocused ? value : `${value}%`}
          onChange={(e) => {
            if (/^\d+$/.test(e.target.value) || e.target.value === '') {
              setValue(e.target.value === '' ? e.target.value : Number(e.target.value));
            }
          }}
          onSelect={() => {
            setInputIsFocused(true);
          }}
          onBlur={(e) => {
            setInputIsFocused(false);
            if (e.target.value === '') setValue(0);
            if ((/^\d+$/.test(e.target.value) && Number(e.target.value) > 100)) setValue(100);
          }}
          type="text"
        />
      </div>
    </div>
  );
}

export default CloudCoverageSlider;