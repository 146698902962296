import { useState, useEffect } from 'react';

import { useTranslation } from '@utils/i18n/i18n';
import { initCookiesProps } from '@utils/Cookies/cookies.utils';

function useCookieProps(useConfig) {
  const { t } = useTranslation();
  const [cookieProps, setCookieProps] = useState(useConfig ? initCookiesProps(t) : {});

  useEffect(() => {
    if (useConfig) {
      setCookieProps(initCookiesProps(t));
    }
  }, [useConfig, t]);

  return cookieProps;
}

export {
  useCookieProps,
}